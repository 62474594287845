<template>
  <div class="items-container">
    <v-row>
      <v-col
        cols="12"
        md="4">
        <SearchBox
          v-model="query.search"
          :disabled="loading"
          @search="getItems(true)" />
      </v-col>
      <v-col
        cols="12"
        md="auto">
        <tour-filters
          v-model="query"
          no-vendor
          no-status />
      </v-col>
      <v-spacer />
      <v-col
        cols="12"
        md="auto">
        <DatePickerDialog
          v-model="query.month"
          type="month"
          format="MMMM BBBB"
          placeholder="วันที่เดินทาง"
          clearable
          :disabled="loading" />
      </v-col>
      <v-col
        cols="12"
        md="auto">
        <v-btn
          color="primary"
          outlined
          depressed
          :ripple="false"
          :loading="exporting"
          style="width: fit-content; background-color: white;"
          @click="onExport()">
          <v-icon class="mr-2">
            mdi-tray-arrow-up
          </v-icon>
          <span>
            นำออกข้อมูล
          </span>
        </v-btn>
      </v-col>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :loading="loading"
          :options.sync="options"
          hide-default-footer
          @update:options="getItems(true)">
          <template #[`item.createdAt`]="{ item }">
            {{ item.createdAt | dateFormat() }}
          </template>
          <template #[`item.tourDate`]="{ item }">
            <span>
              {{ item.startDate | dateFormat() }} - {{ item.endDate | dateFormat() }}
            </span>
          </template>
          <template #[`item.name`]="{ item }">
            <span v-if="item.firstName || item.lastName">
              {{ `${item.firstName} ${item.lastName}` }}
            </span>
            <span v-else>
              -
            </span>
          </template>
          <template #[`item.tel`]="{ item }">
            {{ item.tel || '-' }}
          </template>
          <template #[`item.crews`]="{ item }">
            {{ item.crews | showNumberFormat() }}
          </template>
          <template #[`item.countries`]="{ item }">
            {{ joinArray(item.translate.countries) }}
          </template>
          <!-- <template #[`item.cities`]="{ item }">
            {{ joinArray(item.cities) }}
          </template> -->
          <template #[`item.actions`]="{ item }">
            <v-menu
              bottom
              left
              offset-y>
              <template #activator="{ on, attrs }">
                <v-btn
                  color="secondary"
                  icon
                  v-bind="attrs"
                  v-on="on">
                  <v-icon>
                    mdi-dots-vertical
                  </v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item
                  :ripple="false"
                  @click="detailItem(item)">
                  <v-list-item-title>
                    ดูข้อมูล
                  </v-list-item-title>
                </v-list-item>
                <v-list-item
                  :ripple="false"
                  @click="confirmDialogToggle(item)">
                  <v-list-item-title>
                    ลบ
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </v-data-table>
      </v-col>
      <v-col cols="12">
        <div class="d-flex justify-end">
          <v-pagination
            v-model="query.page"
            :length="totalPages"
            :total-visible="7"
            circle
            color="primary" />
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import { ExportToCsv } from 'export-to-csv'
import { mapActions } from 'vuex'
import SearchBox from '@/components/SearchBox.vue'
import DatePickerDialog from '@/components/DatePickerDialog.vue'
import TourFilters from '@/components/TourFilters.vue'
import PrivateToursProvider from '@/resources/private-tours.provider'

const PrivateToursService = new PrivateToursProvider()

export default {
  components: {
    SearchBox,
    TourFilters,
    DatePickerDialog
  },
  data: () => ({
    loading: false,
    exporting: false,
    headers: [
      {
        text: 'วันที่ทำรายการ',
        value: 'createdAt',
        class: 'table-header',
        align: 'center',
        sortable: true,
        width: '150'
      },
      {
        text: 'วันที่เดินทาง',
        value: 'tourDate',
        class: 'table-header',
        align: 'center',
        sortable: true,
        width: '180'
      },
      {
        text: 'ชื่อ-นามสกุล',
        value: 'name',
        class: 'table-header',
        cellClass: 'limit-text',
        sortable: false,
        width: '250'
      },
      {
        text: 'เบอร์โทรศัพท์',
        value: 'tel',
        class: 'table-header',
        sortable: false
      },
      {
        text: 'ทวีป',
        value: 'translate.continent',
        class: 'table-header',
        sortable: false
      },
      {
        text: 'ประเทศ',
        value: 'countries',
        class: 'table-header',
        cellClass: 'limit-text',
        sortable: false
      },
      // {
      //   text: 'เมือง',
      //   value: 'cities',
      //   class: 'table-header',
      //   sortable: false
      // },
      {
        text: 'จำนวนคนเดินทาง',
        value: 'crews',
        class: 'table-header',
        sortable: false,
        align: 'center',
        width: '150'
      },
      {
        text: '',
        value: 'actions',
        class: 'table-header',
        sortable: false,
        align: 'right'
      }
    ],
    items: [],
    options: {
      sortBy: ['createdAt'],
      sortDesc: [true]
    },
    query: {
      page: 1,
      limit: 10,
      search: '',
      continents: [],
      countries: [],
      status: null,
      month: ''
    },
    totalPages: 1
  }),
  watch: {
    'query.page': {
      handler () {
        this.getItems()
      },
      deep: true
    },
    'query.continents': {
      handler () {
        this.getItems(true)
      },
      deep: true
    },
    'query.countries': {
      handler () {
        this.getItems(true)
      },
      deep: true
    },
    'query.status': {
      handler () {
        this.getItems(true)
      },
      deep: true
    },
    'query.month': {
      handler () {
        this.getItems(true)
      },
      deep: true
    }
  },
  // mounted () {
  //   this.getItems()
  // },
  methods: {
    ...mapActions({
      setSnackbar: 'Snackbar/setSnackbar'
    }),
    changeRoute (to) {
      this.$router.push({ name: to })
    },
    async getItems (isClear = false) {
      try {
        this.loading = true

        if (isClear) {
          this.query.page = 1
          this.items = []
        }

        const { data } = await PrivateToursService.getItems({
          ...this.query,
          sortBy: this.options.sortBy[0],
          sortOrder: this.options.sortDesc[0] ? 'desc' : 'asc'
        })

        this.items = data.results
        this.totalPages = data.pages
      } catch (error) {
        console.error('getItems', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onRemove (id) {
      try {
        this.loading = true

        await PrivateToursService.deleteItemById(id)

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: 'ลบข้อมูลเสร็จสิ้น',
          type: 'success',
          timeout: 2000
        })

        this.getItems(true)
      } catch (error) {
        console.error('onRemove', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onExport () {
      try {
        this.exporting = true

        const { data } = await PrivateToursService.getItems({
          ...this.query,
          page: 1,
          limit: 99999,
          sortBy: this.options.sortBy[0],
          sortOrder: this.options.sortDesc[0] ? 'desc' : 'asc'
        })

        const options = {
          filename: 'private-tours',
          showLabels: false,
          useKeysAsHeaders: false
        }

        const csvExporter = new ExportToCsv(options)
        csvExporter.generateCsv([
          {
            createdAt: 'วันที่ทำรายการ',
            tourDate: 'วันที่เดินทาง',
            name: 'ชื่อ-นามสกุล',
            tel: 'เบอร์โทรศัพท์',
            continent: 'ทวีป',
            countries: 'ประเทศ',
            crews: 'จำนวนคนเดินทาง'
          },
          ...data.results.map((v) => ({
            createdAt: this.$options.filters.dateFormat(v.createdAt),
            tourDate: `${this.$options.filters.dateFormat(v.startDate)} - ${this.$options.filters.dateFormat(v.endDate)}`,
            name: `${v.firstName} ${v.lastName}`,
            tel: v.tel,
            continent: v.continent,
            countries: v.countries.join(', '),
            crews: v.crews
          }))
        ])
      } catch (error) {
        console.error('onExport', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.exporting = false
      }
    },
    detailItem (item) {
      this.$router.push({
        name: 'PrivateTourDetail',
        params: {
          id: item.id
        }
      })
    },
    confirmDialogToggle (item) {
      this.$store.dispatch('ConfirmDialog/setDialog', {
        active: true,
        title: 'แจ้งเตือน',
        text: `คุณยืนยันที่จะลบ "${item.name}" ใช่หรือไม่?`,
        onConfirm: () => this.onRemove(item.id)
      })
    },
    joinArray (arr) {
      return arr.length ? arr.join(', ') : '-'
    }
  }
}
</script>

<style scoped>
.items-container {
  width: 100%;
  padding: 0.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
</style>
